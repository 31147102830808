html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: Assistant, -apple-system, BlinkMacSystemFont, Arial, sans-serif;
	color: #3c3f49;
}

p {
	margin: 0;
	font-size: 20px;
	line-height: 1.5;
	color: #737373;
}

:focus-visible {
	outline: 2px solid #444444;
	border-radius: 2px;
}
